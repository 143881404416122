<template>
     <div>
          <div class="">
               <div class="banner ">
                    <div class="container">
                         <h1>Privacy Policy</h1>
                         <p style="color:white;margin-top:20px;">Updated December 10, 2020</p>
                    </div>
               </div>

               <div class="container content">
                    <h2>OVERVIEW</h2>
                    <p>
                         The use of the nestease.com application and Service (the “Service”) provided by our company (“we,” “us,” “Nestease”) is
                         subject to the provisions of this privacy policy (“Policy”), including any updates. This Policy is incorporated into and is
                         subject to our ToS (“Terms”). If there are any inconsistencies between this Policy and our Terms, this Policy will control.
                         If you do not agree with any aspect of this Policy, you must not use the Service.
                    </p>

                    <p>
                         This Policy, together with our ToS, and any other underlying agreements or terms, explains how any data we collect from you,
                         or that you provide to us, will be processed by us. By using our Service you are accepting the practices described in this
                         Policy. This Policy also describes the manner in which we process, use and disclose information that you provide to us when
                         you use our Service.
                    </p>

                    <p>
                         By using this Service, you consent to the use of the information that you provide to us in accordance with this Policy. We do
                         update this Policy from time to time, so please review this Policy regularly. If we materially alter our Policy, we will
                         notify you of such changes by posting a notice on our Service. Your continued use of the Service will be deemed your
                         agreement that your information may be used in accordance with the new policy. If you do not agree with the changes, then you
                         should stop using the Service, and you should notify us that you do not want your information used in accordance with the
                         changes.
                    </p>

                    <h2>YOUR AGREEMENT WITH US</h2>
                    <p>
                         You affirm that you (1) are at least eighteen (18) years old; (2) are a United States resident; (3) have authority to agree
                         to this Privacy Policy; and (4) are competent and capable of understanding, agreeing to, and complying with this Privacy
                         Policy. If you are a Provider, or work for a Provider, you also confirm that you are legally able to work in the United
                         States.
                    </p>

                    <p>
                         By interacting with our Service, you signify that you have read, understand, and agree to the terms of this Policy. You agree
                         that this Policy is supported by reasonable and valuable consideration (which consideration includes your use of our Site),
                         and you acknowledge your receipt of and the sufficiency of the consideration.
                    </p>

                    <p>
                         When you are no longer an active user of the Service, we may continue to use and share your information as described in this
                         Policy.
                    </p>

                    <p>
                         In this Policy, we use the terms “personal information,” “your data,” or similar to describe information that can be
                         associated with a specific person and used to identify that person. We do not consider this to include information that has
                         been aggregated and/or anonymized so that it does not identify a specific user. We will own any and all such aggregated and
                         anonymized information. “Personal information,” “your data,” or similar for purposes of this Policy does not include (and our
                         commitments in this Policy therefore do not apply to) profile information, messaging, or other information that would be
                         visible to other users of our Service or visitors to our website.
                    </p>

                    <h2>SHARING OF YOUR DATA</h2>
                    <p>
                         Our use of the personally identifiable information we collect is limited to what is reasonably necessary to provide you with
                         the Services, to communicate with you, to market the service and related services to you, and to manage your registered user
                         account, if you have one. We may also share data and images with third parties who may acquire our company or the Service,
                         and with third parties with whom we work to develop additional service offerings to you and others. We do not sell, lease, or
                         share your personally identifiable information with anyone other than the service partners described in this Policy, and the
                         relevant Customers or Providers, as necessary to provide our Service to you and to them.
                    </p>

                    <p>
                         We share data with our service providers, including ad networks, Service analytics companies, and hosting partners. We also
                         share information we have about you for the purposes described in this Policy, including to provide the Service, and our
                         services to you, to provide information to Customers and Providers as relevant to your particular use of the Service, and as
                         described above.
                    </p>

                    <p>
                         We do not otherwise sell, license, or share information with any other individuals or entities that individually identifies
                         our users. However, we may share your information with affiliated businesses, third parties as necessary to provide the
                         Service to you, in response to legal process, legal requests, insurance claims, as related to damage incidents, claims that
                         any content violates the rights of third parties, or as otherwise required by law or for protection of the rights, property,
                         or personal safety of our users, us, or our personnel.
                    </p>

                    <h2>COLLECTION OF INFORMATION AND USE OF COOKIES</h2>
                    <p>
                         In operating our Service, we collect information from you and that you provide to us, when you create an account, offer a
                         service, purchase a service, or simply visit our website or access the app. For example, when you create an account with us
                         we collect your name, e-mail address, contact information, and home or property address. We may use device IDs, cookies, and
                         other signals, including information obtained from third parties, to associate browser sessions, accounts, and/or devices
                         with you. We also collect information from you when you communicate with us or with others via the Service, or when you post,
                         comment, upload or store content.
                    </p>

                    <p>
                         We store and process all content, messaging, projects and their data, and service offering information. This allows us to
                         develop and deliver features and content.
                    </p>

                    <p>
                         When you offer or purchase a service, you share your information with us, including your payment, location, and necessary
                         user account information with actual and potential Customers and Providers, and payment processing and data processing
                         partners.
                    </p>

                    <p>
                         We collect information from your computers or mobile devices, either directly on the Service, through the use of web beacons
                         and cookies, or via our advertising network and analytics partners (including Google and Facebook), regarding your activity
                         on the Service. This includes data such as IP addresses, cookie information, mobile device, advertising identifiers, browser
                         type and version, operating system type and version, mobile network information, device settings, and software data. This
                         information includes the content and ads viewed or clicked on, the frequency and duration of your activity, and your
                         navigation of the Service and other Services.
                    </p>

                    <p>
                         Nestease collects the following types of personal information from various users at various points within the Service, though
                         not all of this information is collected or relevant for all users:
                    </p>

                    <p>
                         Name, postal address, Internet Protocol address, email address, telephone number, occupation, employer, bank account number,
                         bank routing number, services offered, services purchased or considered, browsing history, search history, interaction with
                         the Service, and physical location or movements.
                    </p>

                    <p>
                         By submitting a telephone number or email address to us, you agree that a representative of Nestease can contact you at the
                         number you provide, potentially using automated technology (including texts/SMS messaging) or a pre-recorded message.
                    </p>

                    <p>
                         We do not require that you accept cookies, and you may disable cookies at any time. However, some functionality of our
                         Service may be impaired if you decline to accept cookies. To disable cookies, follow the instructions in your web browser. (A
                         “cookie” is a piece of text which asks permission to be placed on your computer’s or device’s hard drive.)
                    </p>

                    <p>
                         We collect information about you from other users, third-parties, and affiliates, including from third-party services such as
                         via Facebook or Google.
                    </p>

                    <h2>HOW WE USE THE INFORMATION</h2>
                    <p>
                         Our data collection allows us and the advertising networks we use on the Service to understand your activity on our Service
                         and on other Services. We may recognize your devices to provide you with personalized experiences and relevant content across
                         the devices you use.
                    </p>

                    <p>
                         We use the data we collect to operate and improve offerings through the Service; to help authenticate your identity when you
                         visit and transact with the Service; to prevent fraud; to remember your preferences and registration information; to present,
                         help measure, and research the effectiveness of our various offerings; to customize e-mail communications (by determining
                         which e-mails you open and act upon); and to customize the content provided to you.
                    </p>

                    <p>
                         We are able to deliver, personalize, and improve our Service by using the data we have about your activities and interests.
                         We may use this data to maintain and improve the Service, fulfill your requests and when authorized by you, contact you, and
                         detect and defend against fraudulent, abusive, or unlawful activity.
                    </p>

                    <p>
                         We will not use personal information we collected for materially different, unrelated, or incompatible purposes without
                         providing you with notice and/or updating this Privacy Policy.
                    </p>

                    <h2>CONTROLS</h2>
                    <p>
                         You have the ability to manage or review your account information, marketing preferences, and user data. For any of these
                         controls or opt-outs, please contact us at contactus@nestease.com.
                    </p>

                    <p>
                         By contacting us, you can do any or all of the following (a) edit your account information and settings, including profile
                         and contact data; (b) unsubscribe from marketing messages or newsletters; (c) opt out of ad network functions; (d) access,
                         manage, and remove geo-location information associated with your account; (e) delete your account login credentials; (f) make
                         your account inaccessible, and/or (g) remove or anonymize any content you have posted to the Service or shared with others on
                         the Service.
                    </p>

                    <h2>BUSINESS TRANSFERS</h2>
                    <p>
                         If the ownership or control of the Service changes as a result of a commercial transaction, we may transfer your information
                         to the new owner, subject to this Policy or its then-current version.
                    </p>

                    <h2>SECURITY AND RETENTION</h2>
                    <p>
                         We have technical and physical safeguards in place to help protect your information. We use industry standard or better
                         encryption when transmitting certain information, such as financial services information or payment information. As you know,
                         no information storage or data transmission system is entirely secure. We also rely on our users to protect their own
                         accounts and personal data, including passwords and login credentials. The transmission of your information through our
                         Service is at your own risk.
                    </p>

                    <h2>PAYMENT PROCESSING</h2>
                    <p>
                         Third-party payment service providers manage and process all payment transactions for our Service. We do not receive,
                         process, store, or view any payment account or credit card information.
                    </p>

                    <p>
                         If you access third party Services through our Service, you are consenting to the terms and privacy policies of those
                         Services. We do not accept any responsibility or liability for those third parties or their policies whatsoever as we have no
                         control over them.
                    </p>

                    <h2>USER’S AGE</h2>
                    <p>
                         The Service is for use by persons who are at least 18 years of age. We do not knowingly collect or maintain personally
                         identifiable information on the Service from persons under 13 years of age, and no part of our Service is directed to persons
                         under 13. If you are under 13 years of age, then please do not use, access or submit any information to the Service at any
                         time or in any manner. If Nestease learns that personally identifiable information of persons under 13 years of age has been
                         collected on the Service without verified parental consent, then Nestease will take the appropriate steps to delete this
                         information.
                    </p>

                    <p>
                         By using our Service, you affirm that you are over 13 years of age. We are not liable for any damages that may result from a
                         user’s misrepresentation of his or her age. Parents or legal guardians of children under 13 cannot agree to these Terms on
                         their behalf.
                    </p>

                    <p>
                         EU Age Limitation: Due to the data and privacy laws in the European Union, we do not ask for or knowingly accept personally
                         identifiable data from EU users under the age of 16. If you are a resident of the EU, by using our Service, you affirm that
                         you are over 16 years of age. We are not liable for any damages that may result from a user’s misrepresentation of his or her
                         age. If Nestease learns that personally identifiable information of residents of the EU under 16 years of age has been
                         collected on the Service without verified parental consent, then Nestease will take the appropriate steps to delete this
                         information.
                    </p>

                    <h2>AD NETWORKS</h2>
                    <p>
                         This Service uses remarketing services to advertise on third party Services (including Google) to previous visitors to our
                         Service. We may advertise to previous visitors who haven’t completed a task on our Service, for example using the contact
                         form to make an inquiry or completing the purchase of an item from the Service. This could be in the form of an advertisement
                         on the Google search results page, or a Service in the Google Display Network, or on Facebook or some other platform or
                         advertising network. Third-party vendors may also use cookies to serve ads based on past visits to our Service.
                    </p>

                    <h2>U.S. COMPANY, CROSS-BORDER DATA</h2>
                    <p>
                         The Service is hosted in the United States. At this time, it is exclusively available in the United States. If you are
                         outside the United States and submit any information to us, you are transferring your personal information to the United
                         States and you consent to that transfer, subject to the terms of this Policy. Wherever you are when accessing and interacting
                         with the Service, you agree that we may display data about our users’ fundraising efforts in multiple countries and worldwide
                         via the Service, and that the data collected by us under the terms of this Policy may cross international borders.
                    </p>

                    <h2>CALIFORNIA PRIVACY RIGHTS</h2>
                    <p>
                         If you reside in California and have provided personally identifiable information to us, you may request information about
                         our disclosure of certain categories of personal information to third parties for their direct marketing purposes under
                         California’s Shine the Light law. Such requests must be submitted to us at contactus@nestease.com.
                    </p>

                    <p>
                         If you are a resident of California, effective January 1, 2020, you have the following rights under the California Consumer
                         Privacy Act of 2018 (“CCPA”):
                    </p>

                    <p>
                         to know what categories of personal information about you we have collected and used, including how the personal information
                         was collected;
                    </p>

                    <p>
                         to know what categories of personal information is being sold or disclosed and if it is being sold or disclosed to third
                         parties and a list of categories of information that are sold, or to know that your personal information has not been sold;
                    </p>

                    <p>
                         to request that your personal information not be sold, please select “Do Not Sell My Personal Information” on this form
                         https://nestease.com/contact;
                    </p>

                    <p>
                         to access your personal information, including the right to download or transfer personal information collected during the
                         previous 12 months (click here for more information on “Access to Specific Information and Data Portability Rights)
                         https://nestease.com/contact
                    </p>

                    <p>
                         to request that your personal information be deleted (click here for more information on “Deletion Request Rights”
                         https://nestease.com/contact; and
                    </p>

                    <p>
                         to equal service and equal price for goods or services even if you exercise your rights under the CCPA.
                    </p>

                    <p>
                         If you are a California resident and you want to exercise any of your rights as set forth above, please contact us at
                         (804)572-9331 or submit your request at https://nestease.com/contact. For all requests, it is helpful to put the statement
                         “California Privacy Rights” in the body of your request, describe the nature of your request, and provide your name, street
                         address, city, state, and zip code. In your request, you need to attest to the fact that you are a California resident and
                         provide a current California address for our response. For more information click here https://nestease.com/privacy.
                    </p>

                    <p>
                         We will not discriminate against you for exercising any of your CCPA rights.
                    </p>

                    <p>CONTACT INFORMATION FOR QUESTIONS, COMPLAINTS AND COMMENTS</p>
                    <p>
                         If you have any questions, comments, or complaints regarding this Policy, please use the CONTACT US link on our website or
                         email us at contactus@nestease.com.
                    </p>
                    <p>Privacy Policy</p>
                    <p>updated December 10, 2020</p>
               </div>
          </div>
     </div>
</template>

<script>
     export default {
          mounted() {
               // this.$refs.video.play();
          },
     };
</script>

<style scoped lang="scss">
     .banner {
          background: url("../assets/images/about/section1banner.jpg");

          height: 400px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          display: flex;
          align-items: flex-end;
          padding-bottom: 80px;
          h1 {
               color: white;
               font-size: 80px;
          }
     }

     .content {
          padding-top: 50px;
          padding-bottom: 100px;
          h2 {
               font-weight: 500;
               max-width: 650px;
               margin-top: 50px !important;
          }

          p {
               max-width: 650px;
               text-align: justify;
          }

          @media screen and (max-width: 700px) {
               padding-top: 50px;
               padding-bottom: 50px;
          }
     }
     .container {
          padding-left: 16px;
          padding-right: 16px;
     }
</style>
