<template>
     <div>
          <div class="">
               <div class="banner ">
                    <div class="container">
                         <h1>About us</h1>
                    </div>
               </div>

               <div class="container content">
                    <h1>Thank you for visiting us.</h1>

                    <p>
                         We are very humbled that we can help connect many neighborhoods, households to local businesses, community providers and
                         freelancers thereby building a better society!
                    </p>

                    <h2>We are Nestease. Your home needs made easy!</h2>
                    <p>
                         We are a technology company that connects households and local businesses/professionals to do their best together.
                    </p>

                    <p>
                         Great things start at home. Nestease is launching with the vision of simplifying all your home needs. Our first product is to
                         provide on demand lawn mowing services using a smartphone.
                    </p>

                    <p>
                         A well-maintained lawn is functional and visually appealing. Over the years, we thought there should be a better way to get
                         this accomplished. Lawn Care companies and the contracts can get expensive. And mowing the lawn yourself is a time consuming
                         chore. We set out on a mission to find a solution for customers, giving them the flexibility of lawn care on demand. That way
                         you control how often you use the service, And with this intention in mind we created Nestease. Through our platform we bring
                         you together with the providers who are happy to service in your area for your on demand lawn mowing needs. No more
                         contracts, searching for providers and quotes. Furthermore, it is contactless. We are here to help you and your community by
                         connecting your needs to our partner providers including local businesses, community providers and freelancers.
                    </p>
               </div>
          </div>

          <div class="">
               <div class="banner banner2">
                    <div class="container">
                         <h1>Our story</h1>
                    </div>
               </div>

               <div class="container content">
                    <h2>We founded Nestease to help communities with their home care needs.</h2>
                    <p>
                         Our first goal was to bring people and resources together and optimize the lawn mowing industry to benefit everyone. With that
                         goal in mind, we launched our first product to provide customers the ability to select lawn mowing service on-demand with a
                         wide range of local and community providers using a smartphone. Through our product, we empower professionals, local
                         businesses and community providers to earn new income by connecting them to a set of new customers that are now so easily
                         accessible.
                    </p>
               </div>
          </div>

          <div class="custpartner">
               <div class="container">
                    <div class="columns is-vcentered m-0">
                         <div class="column">
                              <div class="banner nester">
                                   <div class="container">
                                        <!-- <h1>Our Customers</h1> -->
                                   </div>
                              </div>
                         </div>
                         <div class="column">
                              <div class="container content">
                                   <h2>Our Customers - The “nester”</h2>
                                   <p>
                                        Whether you are an occasional or recurring customer, we ease your needs with our high quality providers saving
                                        money, time and energy.
                                   </p>
                              </div>
                         </div>
                    </div>
               </div>

               <div class="container">
                    <div class="columns is-vcentered  m-0">
                         <div class="column is-hidden-tablet">
                              <div class="banner easer">
                                   <div class="container">
                                        <!-- <h1>Our Partners</h1> -->
                                   </div>
                              </div>
                         </div>
                         <div class="column">
                              <div class="container content">
                                   <h2>Our Partners - The “easer”</h2>
                                   <p>
                                        With our product, you get the financial freedom and flexibility to run your own business.
                                   </p>
                              </div>
                         </div>
                         <div class="column is-hidden-mobile">
                              <div class="banner easer">
                                   <div class="container">
                                        <!-- <h1>Our Partners</h1> -->
                                   </div>
                              </div>
                         </div>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {
          mounted() {
               // this.$refs.video.play();
          },
     };
</script>

<style scoped lang="scss">
     .banner {
          background: url("../assets/images/about/about.jpg");

          height: 450px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          display: flex;
          align-items: center;

          h1 {
               color: white;
               font-size: 80px;
               text-align: center;
          }
     }

     .banner2 {
          background: url("../assets/images/about/section2banner2.jpg");
          background-repeat: no-repeat;
          background-position: center -200px;

          @media screen and (max-width: 900px) {
               background-position: center;
          }
          background-size: cover;
     }

     .nester {
          background: url("../assets/images/about/nester.jpg");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          height: 550px;
     }

     .easer {
          background: url("../assets/images/about/easer.jpg");
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          height: 550px;
     }

     .content {
          padding-top: 70px;
          padding-bottom: 70px;
          max-width: 700px !important;

          h2 {
               font-weight: 500;
          }

          p {
               text-align: justify;
               font-size: 18px;
          }

          @media screen and (max-width: 700px) {
               padding-top: 50px;
               padding-bottom: 50px;
          }
     }

     .custpartner {
          margin: 50px auto 130px;
          h1 {
               font-size: 50px;
               max-width: 100%;
          }
          .content {
               padding: 60px 30px;
          }
     }
     .container {
          padding-left: 16px;
          padding-right: 16px;
     }
</style>
