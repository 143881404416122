<template>
     <div>
          <div class="">
               <div class="banner ">
                    <div class="container">
                         <h1>FAQs</h1>
                    </div>
               </div>

               <div class="container content">
                    <h2>Frequently Asked Questions</h2>

                    <VueFaqAccordion :items="faqs" class="faqs" />
               </div>
          </div>
     </div>
</template>

<script>
     import VueFaqAccordion from "vue-faq-accordion";
     export default {
          data() {
               return {
                    faqs: [
                         {
                              title: "Is This App free to Download? Where is it Available?",
                              value: `- Yes, it is free to download<br>
- It is available in both the Apple Store and Google Play Store`,
                         },
                         {
                              title: "How long does it take to sign up as a Partner Provider?",
                              value: `- Signing up to become a Partner Provider is a simple 3 step process that takes ~10 minutes.<br>
    - First, you enter the basic information like your mobile phone number, address, miles range, mower type etc .<br>
    - Second, enter your bank details for payments. Nestease uses Stripe for payment processing and you will enter your Bank information in the Stripe screen integrated through the application.<br>
    - Third, if you have additional crew members, you will be prompted to add their names to the application for service requests.`,
                         },
                         {
                              title: "Can I add my crew members? Where can I see my crew members added?",
                              value: `- Yes, you can add them when you sign up or later on. Go to the Profile page to add or view them. They will be receiving an invitation notification on their phone to onboard to Nestease Partner.`,
                         },

                         {
                              title: "When and where should I work?",
                              value: `- You can get services requests any time you want as long as you are online. You service the requests based on the date and time requested by the customer. Time is usually optional.<br>
- You will get service requests within the miles range you set up from your address. Currently the max miles range you can serve is up to 30m from your address. For eg., if you set up 20 as the max miles you can serve, then you will get service requests from customers within 20 miles of your address entered.`,
                         },

                         {
                              title: "How is Covid-19 handled? Are there any risks?",
                              value: `- Our App and processes are contactless. You do not need to make face-to-face contact with the customer, since all work is managed through the App.`,
                         },

                         {
                              title: "How do I get paid?",
                              value: `- Once you complete the work by adding before and after pictures and clicking done in the App, the App processes the payment from the customer's saved card and will send the payment to you. All payments are made through our App using Stripe weekly. Please ensure the appropriate banking information is added for prompt weekly payment.`,
                         },

                         //                          {
                         //                               title: "Can I accept service requests and do the job without a lawn mower?",
                         //                               value: `- Yes, there are cases where a customer may give you access to their personal mower. In those cases, we ask that you ensure the mower is kept clean immediately after usage. The customer will keep the mower outside in order for you to use.<br>
                         // - If you don’t have a lawn mower and that’s the option you have selected in the App, then you will only get requests from customers who are willing to provide one.`,
                         //                          },

                         {
                              title: "Is there a pay difference for the type of Lawn mower being used to mow?",
                              value: `- No, currently all of them have the same service fee.`,
                         },

                         // {
                         //                               title: "Why do I get 2 service fees with certain customer service requests?",
                         //                               value: `- The App generates one service request with 2 fee types, one “using customer’s lawn mower” and another one with “using your lawn mower”. <br>
                         //     - The first one in this case is the one where the customer is willing to provide you his lawn mower. If you select that option, then you may want to use the customer's lawn mower to do the job since the service fee for this one is a few dollars less.<br>
                         //     - The second one is using the service providers lawn mower to do the job.<br>
                         //     - You can choose either one`,
                         //                          },

                         {
                              title: " What is the difference between a Community Provider and a Professional?",
                              value: `- Community Providers are individuals who are closely connected with their community, it could be someone who lives in the same or nearby neighbourhood as the customer. It might also be someone who does this job as a freelancer.<br>
- Professionals are individuals that are part of a local or nationwide business establishment.`,
                         },

                         {
                              title: " How is the service fee calculated?",
                              value: `- We calculate the initial fee based on market research and the lot size provided by the customer. Additionally, a provider has the option to provide a new quote (service fee) for the first 2 times a customer is being served. We then use those 2 quotes along with other criteria to calculate a new fee.<br>
- We ask for 2 quotes from providers for any customer’s lawn so as to allow the provider to ensure the service fee is reasonable with the market. We understand lots and lawn mowing can vary based on lay of the land, size of the house, number of trees, bushes etc..`,
                         },

                         {
                              title: "What is considered a lawn mowing service request?",
                              value: `- A typical service request should cover end-to-end lawn mowing services like mowing based on customers notes (if the customer had stated anything specific), trimming, blowing or bagging etc..`,
                         },
                    ],
               };
          },
          components: {
               VueFaqAccordion,
          },
          mounted() {
               // this.$refs.video.play();
          },
     };
</script>

<style scoped lang="scss">
     .banner {
          background: url("../assets/images/faq/banner.jpg");

          height: 600px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          display: flex;
          align-items: flex-end;
          padding-bottom: 80px;
          h1 {
               color: white;
               font-size: 80px;

          }
     }

     .content {
          padding-top: 100px;
          padding-bottom: 100px;
          h2 {
               font-weight: 500;
               max-width: 650px;
               margin-top: 50px !important;

               @media screen and (max-width: 1000px) {
                    text-align: center;
                        margin-top: 20px !important;
               }
          }

          p {
               max-width: 650px;
               text-align: justify;
          }

          @media screen and (max-width: 700px) {
               padding-top: 50px;
               padding-bottom: 50px;
          }
     }
     .container {
          padding-left: 16px;
          padding-right: 16px;
     }
</style>

<style lang="scss">
     .faqs {
          // .qn {
          //      font-size: 27px;
          //      @media only screen and (max-width: 600px) {
          //           font-size: 19px;
          //      }
          // }
          // .ans {
          //      margin-top: 20px;

          //      @media only screen and (max-width: 600px) {
          //           margin-top: 10px;
          //      }
          //      font-size: 17px;
          //      color: #73738d;
          //      max-width: 450px;

          //      p {
          //           margin-bottom: 10px;
          //      }
          //      min-height: 50px;
          // }
          padding: 0 !important;

          .accordion__toggle-button::before,
          .accordion__toggle-button::after {
               background: #73738d !important;
          }

          .accordion__title-text {
               color: rgba(0, 0, 0, 0.7);
               font-size: 20px;
               font-weight: 500;
               text-align: left;
               margin-bottom: 0 !important;

               @media only screen and (max-width: 600px) {
                    font-size: 19px !important;
                    text-align: left;
               }
          }

          .accordion__value * {
               color: #5a5a6d;
               font-size: 17px;
          }
          .accordion {
               display: block;
          }
          .faq-wrapper {
               display: block;

               @media only screen and (max-width: 600px) {
                    padding: 10px !important;
               }
          }
     }
</style>
