<template>
     <div>
          <div class="">
               <div class="banner ">
                    <div class="container">
                         <h1>Terms Of Service</h1>
                         <p style="color:white;margin-top:20px;">Updated March 12, 2021</p>
                    </div>
               </div>

               <div class="container content">
                    <div style="margin: 16px;">
                         <h2>Overview</h2>
                         <p>
                              These ToS and license terms (these “ToS”) is a binding agreement between you, including Providers and Customers as
                              defined in this ToS (“you,” “your,”) and Nestease, LLC, a Virginia limited liability company with offices in Glen Allen,
                              Virginia (“Nestease,” “we,” “us,” “our”).&nbsp; By accessing or using the Nestease application (“Nestease app”), web
                              site, software, and related services (all together, the “Service”) each site visitor and user of the Service
                              acknowledges having read, understood and agrees to be bound by the ToS and Nestease’s privacy policy (on our website at
                              https://nestease.com/).&nbsp; If you do not so agree, you may not download, access or use the Service.
                         </p>

                         <p>
                              We may update or modify the ToS in our discretion, and will post the updated terms to https://nestease.com/terms.&nbsp;
                              The updated ToS will be effective as of the date indicated in the ToS. We may provide notice of the updates by email,
                              text, and/or an on-screen notification. Your exclusive remedy if you do not accept the updated ToS is to cease your
                              access to and use of the Service. Any use of the Service after the effective date means you have accepted the new ToS.
                         </p>

                         <p>
                              Within the ToS, users of the Service who may or actually do purchase goods or services from third-party providers are
                              called “Customers.”&nbsp; Individuals or entities that offer and provide services to Customers are called
                              “Providers.”&nbsp; Customers, Provider, site visitors, users of the Service, and Nestease are referred to herein as “All
                              Parties.”
                         </p>

                         <h2>All Parties agree:</h2>
                         <ul>
                              <li style="font-weight: 400;">
                                   Customers are solely responsible for compensation to Providers, and for insuring a safe environment for the
                                   Providers to deliver their services
                              </li>
                              <li style="font-weight: 400;">
                                   Providers are solely responsible for providing services to Customers, and for any related actions, damages, claims,
                                   acts, or failures to act; each Provider is responsible for procuring its own insurance coverage for its activities
                              </li>
                              <li style="font-weight: 400;">
                                   Nestease is not a Customer or a Provider; Nestease is not an insurer against damages or injuries to any person,
                                   property, or equipment
                              </li>
                         </ul>

                         <p>
                              Your Content and Information .&nbsp; You represent and warrant that you will provide only accurate, complete, and
                              non-misleading content and data to the Service. You further represent and warrant that you will act quickly to correct
                              any unintentional errors with regard to Your Content (as defined herein). Each Provider and each Customer will be solely
                              responsible for any and all service descriptions, pricing data, photos, text, graphics, videos, images, or other
                              material that it uploads to the Service (“Your Content”). You grant us a non-exclusive, fully paid, royalty-free license
                              to access, use, copy, modify (including the right to create derivative works), display, reproduce, and transmit Your
                              Content for the provision of the Service by Nestease and its partners and necessary service providers. You agree that
                              Nestease may use Your Content to suggest or promote services that may be of interest to you, at any time.
                         </p>

                         <p>
                              You are responsible for the accuracy, content, intellectual property rights and licenses, and legality of Your Content,
                              and any indexing, communication, display, or transfer of Your Content outside of the Service by you or any third-party
                              authorized by you. You represent, warrant and covenant that you have all rights necessary to upload Your Content to the
                              Service and to otherwise have Your Content used and shared thereafter.
                         </p>

                         <h2>Rights of Nestease, Trademark Use</h2>

                         <p>
                              Nestease owns or has license to all rights, title, interest, copyright and other intellectual property rights in and to
                              the Service and all content and other aspects thereof.&nbsp; All Parties agree that Nestease has a license to retain,
                              use, and copy any records, descriptions, images, or content related to Provider services or Customer properties or
                              projects.
                         </p>

                         <p>
                              The trademarks, service marks, and logos used and displayed on the Service are trademarks or service marks of Nestease
                              or of their respective owners. The Trademarks may not be used to disparage Nestease or any third party, Nestease’s or
                              any other product or service, or in any manner that may damage any goodwill in the Nestease trademarks. Nestease hereby
                              grants to you a limited, revocable license to use Nestease’s word marks and logos on your web site and signage&nbsp;
                         </p>

                         <p>Any rights not expressly granted herein are reserved by Nestease and its third-party service providers.</p>

                         <p>
                              Third-Party Content .&nbsp; You may see or receive content from parties not owned or controlled by you or by us,
                              including our partners, or other users of the Service (“Third-Party Content”). Such Third-Party Content and the Service
                              are subject to United States and other intellectual property laws. Unauthorized use of the Service and/or Third-Party
                              Content may be illegal. You agree not to use, copy, share, distribute, or display any content or data not contributed by
                              you, except as permitted under these ToS, or by express, prior written consent. Under no circumstances will Nestease be
                              liable in any way for any Third-Party Content, including liability for any errors or omissions, loss or damage of any
                              kind.
                         </p>

                         <p>
                              Nestease does not review, approve, disapprove, or legally vet Your Content or Third-Party Content, but we do reserve the
                              right to refuse to accept, or delete Your Content or Third-Party Content in our discretion. We have the right (but not
                              the obligation) in our discretion to reject, remove, or modify any content that is subject to objection or demand by any
                              person or entity, or which we reasonably consider to be in violation of these ToS or applicable law.
                         </p>

                         <h2>Use of the Platform</h2>

                         <p>
                              No person or entity is authorized to re-sell, sublicense, reverse engineer, decompile, disassemble, modify, translate,
                              or create derivative works of or related to the Nestease software, web site, code, content, copyrightable element, or
                              any other aspect or component of the Service.&nbsp;&nbsp;
                         </p>

                         <p>
                              No person or entity may use or access the Service to build or support, and/or assist a third party in building or
                              supporting products or services competitive with Nestease or the Service.
                         </p>

                         <p>
                              No person or entity may remove or obscure any proprietary notices or labels from any aspect of the Service or use the
                              Service for any fraudulent undertaking or in any manner that could damage, disable, overburden, impair or otherwise
                              interfere with the Service.
                         </p>

                         <p>
                              Nestease’s consumer users (customers of Providers) agree to and are bound by Nestease’s consumer terms of service. Those
                              terms are found here, https://www.nestease.com/terms and are incorporated herein by reference. To the extent that these
                              consumer terms affect and implicate you, your business, and your relationship with Nestease, you agree to be bound by
                              them.
                         </p>

                         <h2>Equipment</h2>

                         <p>
                              In the event that Customer-supplied equipment or supplies are used by a Provider for any project or service related to
                              the Service, the Customer and Provider agree that such equipment will be offered to the Provider on an as-is and
                              where-is basis, with no guarantee by Customer that the equipment is safe or suitable. Provider therefore agrees to use
                              any and all such equipment or supplies at Provider’s own risk. Customer agrees that Customer’s sole remedy for any
                              alleged damage or claim related to any and all such equipment or supplies shall be against Provider.
                         </p>

                         <h2>Payments</h2>

                         <p>
                              Nestease uses third-party processing services (which may include Stripe, Square, PayPal, or other banking and processing
                              partners) to process payments from Customers for service orders placed through the Service. Each of these processing
                              services charge a fee, which together with our service charges will be netted out of payments to be sent to Providers.
                              We will deliver the balances to your bank account by electronic transfer only (we will not send paper checks) as quickly
                              as possible, generally within 10 days. As a condition to receiving payments due to you from Nestease, you agree to
                              provide complete information and authorizations as reasonably required, and to provide complete, accurate, and current
                              information at the initiation of the Service, promptly upon request from Nestease, and upon any changes in your banking
                              or other relevant details. In the event that there is a mistake in calculating, reporting, charging, or submitting any
                              payment or amount at any time, Provider and Customer hereby authorize Nestease, our banks, and our payment processing
                              vendors to charge or retrieve additional amounts, net out additional amounts from future payments or balances, submit an
                              additional amount to you in a later transmittal, or otherwise take corrective action as necessary to remedy such error
                              within a reasonable amount of time following Nestease’s actual knowledge and verification of any such error.
                         </p>

                         <p>
                              As a general matter, to provide the Customer with an opportunity to inspect a Provider’s work and given notice of any
                              concerns, we will process payment from the Customer approximately 12 hours after a Provider marks a service as complete.
                         </p>

                         <h2>Suggestions</h2>

                         <p>
                              You may submit suggestions, ideas, feedback, recommendations, or requests. By doing so, you irrevocably assign to
                              Nestease all right, title, interest and intellectual property rights to such matters.
                         </p>

                         <h2>Your Account</h2>

                         <p>
                              You are wholly responsible for maintaining the confidentiality and security of your username and password, and you are
                              wholly liable for all activities occurring thereunder. Nestease cannot and will not be liable for any loss or damage
                              arising from a user's failure to comply with this section, including any loss or damage arising from your failure to
                              secure your account or to immediately notify Nestease of any unauthorized use of a password or account or any other
                              breach of security.
                         </p>

                         <h2>Third Parties</h2>
                         <p>
                              Your use of the Service may be subject to separate agreements you have with your device operating system provider (e.g.,
                              Apple, Google, Microsoft), manufacturer (e.g., Apple, Samsung), carrier (e.g., AT&amp;T, Verizon), browser software
                              provider (e.g., Apple, Google, Microsoft, Mozilla) and other parties involved in providing your service. Third party
                              providers and our payment processor are covered third parties in these terms.&nbsp; You agree to comply with all
                              applicable third-party terms when using the Service. We are not a party to those agreements and have no responsibility
                              for the products and services provided by third parties.&nbsp; Covered third parties have no obligation to provide
                              maintenance, support, or any warranty or guarantee of interoperability or support for the Service.
                         </p>
                         <h2>Privacy</h2>

                         <p>
                              Nestease’s Privacy Policy describes what information we collect from you and other users of the Service, and how we use
                              Your Content and other information obtained through the Service. We encourage you to read the Privacy Policy carefully
                              as it forms a binding part of these ToS, and contains important information about your rights.
                         </p>

                         <p>Limitation of Liability and Disclaimer of Warranties</p>

                         <p>
                              EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH HEREIN, NESTEASE AND ITS PROVIDERS DISCLAIM ALL WARRANTIES, EXPRESS OR
                              IMPLIED, FOR THE SERVICE, INCLUDING BUT NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A
                              PARTICULAR PURPOSE.&nbsp; NESTEASE AND ITS PROVIDERS MAKE NO REPRESENTATIONS OR WARRANTIES REGARDING THE RELIABILITY,
                              AVAILABILITY, TIMELINESS, CONSISTENT AVAILABILITY, ACCURACY OR COMPLETENESS OF THE SERVICE OR THE RESULTS YOU MAY OBTAIN
                              BY USING THE SERVICE. YOU AGREE THAT YOUR SOLE REMEDY IN THE EVENT OF ANY DAMAGE, HARM, CLAIM, OR UNSATISFACTORY SERVICE
                              PROVIDED OR PAYMENT EXPECTED SHALL BE AGAINST THE RELEVANT PROVIDER OR CUSTOMER, AND NOT AGAINST NESTEASE.
                         </p>

                         <p>
                              NEITHER NESTEASE NOR ITS PROVIDERS CONTROL THE TRANSFER OF DATA OVER COMMUNICATIONS FACILITIES, INCLUDING THE INTERNET,
                              AND YOU AGREE THAT THE SERVICE MAY BE SUBJECT TO LIMITATIONS, DELAYS, AND OTHER PROBLEMS INHERENT IN THE USE OF SUCH
                              COMMUNICATIONS FACILITIES. NESTEASE IS NOT RESPONSIBLE FOR ANY DELAYS, DELIVERY FAILURES, OR OTHER DAMAGE RESULTING FROM
                              SUCH PROBLEMS. WITHOUT LIMITING THE FOREGOING, NESTEASE DOES NOT WARRANT OR GUARANTEE THAT ANY OR ALL SECURITY ATTACKS
                              WILL BE DISCOVERED, REPORTED OR REMEDIED, OR THAT THERE WILL NOT BE ANY SECURITY BREACHES BY THIRD PARTIES. EXCEPT WHERE
                              EXPRESSLY PROVIDED OTHERWISE BY NESTEASE, THE SERVICE ARE PROVIDED TO MERCHANT ON AN "AS IS" BASIS.
                         </p>

                         <p>
                              IN NO EVENT WILL NESTEASE BE LIABLE UNDER ANY LEGAL THEORY INCLUDING BUT NOT LIMITED TO CONTRACT, NEGLIGENCE, STRICT
                              LIABILITY OR OTHER THEORY, FOR ANY DIRECT, INDIRECT, SPECIAL, PUNITIVE, INCIDENTAL OR CONSEQUENTIAL DAMAGES, LOST
                              PROFITS, OR DAMAGES RESULTING FROM LOST DATA, LOST OR MISTAKEN ORDERS, OR BUSINESS INTERRUPTION RESULTING FROM OR IN
                              CONNECTION WITH THE USE OR INABILITY TO USE THE SERVICE, EVEN IF NESTEASE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
                              DAMAGES AND EVEN IF A REMEDY HAS FAILED ITS ESSENTIAL PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY LAW, NESTEASE’S
                              AGGREGATE LIABILITY TO YOU OR TO ANY THIRD PARTIES IN ANY CIRCUMSTANCE IS LIMITED TO ONE HUNDRED DOLLARS ($100).
                         </p>

                         <p>
                              If these exclusions of warranties or limitations of liability are not enforceable under applicable state law, THE
                              LIABILITY OF NESTEASE AND ITS PROVIDERS AND CONTRACT PARTNERS WILL BE LIMITED TO THE MAXIMUM EXTENT PERMITTED BY LAW.
                         </p>

                         <h2>Indemnification</h2>

                         <p>
                              You and all Customers and Providers agree to defend, indemnify and hold harmless Nestease and its directors, officers,
                              employees, affiliates, third-party service providers, and agents from and against any claims, actions or demands,
                              including, without limitation, reasonable attorneys’ fees, arising from or relating to the Service, any service order,
                              service, payment, lack of payment, or any breach of these ToS. Nestease will provide notice to you of any such claim,
                              suit, or proceeding. Nestease reserves the right to assume the exclusive defense and control of any matter which is
                              subject to indemnification under this Section. In such case, you agree to cooperate with any reasonable requests
                              assisting Nestease’s defense of such matter.
                         </p>

                         <h2>Termination</h2>

                         <p>
                              These ToS continue to govern your use of the Service unless and until such time as you or we terminate your use of any
                              applicable Service. Upon termination, you will lose access to the relevant Service, and we may delete any information
                              stored regarding you or your transactions through the Service. Termination of your use of any of the Service does not
                              relieve you of the obligation to pay for any Purchases made as well as related fees and charges already incurred. In the
                              event of termination of your use of the Service by you or us, we will not provide any refunds for amounts previously
                              paid through the Service.
                         </p>

                         <p>
                              Nestease reserves the right, in its sole discretion, to modify, suspend, or terminate these ToS and your access to all
                              or any part of the Service or Your Content at any time and for any reason without prior notice, and without liability.
                              Nestease reserves the right to modify, suspend, or discontinue all or any part of the Service at any time without prior
                              notice, and without liability.
                         </p>

                         <h2>Compliance</h2>

                         <p>
                              You agree to comply with all laws, rules and regulations applicable to Provider’s business in relation to your use of
                              the Service, including&nbsp;
                         </p>
                         <ul>
                              <li style="font-weight: 400;">
                                   any applicable tax laws and regulations, the Payment Card Industry Data Security Standards as made available at
                                   https://www.pcisecuritystandar... and the by-laws,&nbsp;
                              </li>
                              <li style="font-weight: 400;">
                                   any and all rules, policies and procedures of credit card networks and other payment processing organizations.
                              </li>
                              <li style="font-weight: 400;">
                                   United States laws controlling the export of products and information.&nbsp; You agree not to export or re-export
                                   any part of the Service. You agree that you are not in a country where such export is prohibited or are a person or
                                   entity for which such export is prohibited. You are solely responsible for compliance with the laws of your
                                   specific jurisdiction regarding the import, export or re-export of the Service.
                              </li>
                         </ul>

                         <h2>Force Majeure</h2>

                         <p>
                              Nestease will not be liable for any failure or delay resulting from any condition beyond its reasonable control,
                              including but not limited to governmental action or acts of terrorism, earthquake, fire, disease, outbreak, flood, or
                              other acts of God, labor conditions, power failures, third-party service interruptions, and Internet disturbances.
                         </p>

                         <h2>Consent to Electronic Communications</h2>

                         <p>
                              By providing your email address and mobile phone number, you agree that we may contact you by these methods, including
                              text or SMS messaging. Carrier charges for your phone or other device may apply to text messages or SMS messages
                              originating with or related to the Service.&nbsp; You consent to receiving such communications. Consent to electronic
                              communications is a condition of our Service.&nbsp; Though you are able to revoke it at any time, if you revoke it you
                              will no longer be able to use the Service. Your consent to receive electronic communications is valid until you revoke
                              it. If you wish to revoke your consent for electronic Communications, please contact contactus@nestease.com.&nbsp; It is
                              your responsibility to keep your email address and mobile phone number accurate and up to date so that we can
                              communicate with you. You can update this information through the Nestease app, when logged in to the web site, or by
                              contacting support.
                         </p>

                         <h2>Disputes, Arbitration</h2>

                         <p>
                              Nestease’s sole role and responsibility with regard to any disputes between you and any Provider or Customer relating to
                              the Service, including any disputes relating to any goods or services offered or provided, any damage, or any actions or
                              failures to act, will be to facilitate communication between the parties to the dispute by sharing contact information
                              and/or by allowing communication via the Service.&nbsp; You agree that in the event of a dispute, Nestease may share
                              your email address, phone number, and other contact information with other parties to the dispute.
                         </p>

                         <p>
                              Any disputes or claims between you and Nestease, whether arising out of or relating to these ToS or not, shall be
                              resolved exclusively through final and binding arbitration, according to the commercial arbitration rules of the
                              American Arbitration Association and subject to the Federal Arbitration Act, as each may be modified by these ToS. You
                              and Nestease waive the right to a trial by jury or to participate in a class action or any other representative action
                              or proceeding. Notwithstanding the foregoing, these ToS shall not preclude either party from pursuing a court action for
                              the sole purpose of obtaining a temporary restraining order or preliminary injunction in circumstances in which such
                              relief is appropriate, provided that any other relief shall be pursued through an arbitration proceeding pursuant to
                              these ToS. All aspects of the arbitration proceeding, and any ruling, decision, or award by the arbitrator, will be
                              strictly confidential for the benefit of all parties.
                         </p>

                         <h2>Survival</h2>

                         <p>
                              Upon termination for any reason of your use of the Service or these ToS, in addition to this section, all provisions
                              governing content rights, intellectual property, your responsibilities, fees and payments, warranties, waivers,
                              limitations of liability, indemnification, termination, arbitration, governing law, and “miscellaneous” shall survive
                              termination.
                         </p>

                         <h2>Assignment</h2>

                         <p>
                              You may not assign these ToS or any rights or obligations hereunder, by any means, without our prior written
                              consent.&nbsp; Any attempted assignment may be void, at our discretion. We reserve the right to freely assign these ToS
                              and the rights and obligations hereunder, to any party without notice or consent. Subject to the foregoing, these ToS
                              shall be binding upon and inure to the benefit of the parties hereto, their successors and permitted assigns.
                         </p>

                         <h2>Governing Law</h2>

                         <p>
                              Any action, claim, or dispute related to these ToS will be governed by the laws of the Commonwealth of Virginia,
                              excluding its conflicts of law provisions, and controlling U.S. federal law. The Uniform Commercial Code, United Nations
                              Convention on Contracts for the International Sale of Goods, and Uniform Computer Information Transactions Act will not
                              apply to these ToS.
                         </p>

                         <h2>Miscellaneous</h2>

                         <p>
                              If any provision of these ToS is found to be invalid by a court of competent jurisdiction, such invalidity will not
                              affect the validity of the remaining provisions. Failure of Nestease to act on or enforce any provision of these ToS
                              will not be construed as a waiver of that provision or any other provision. No waiver will be effective against Nestease
                              unless made in writing, and no such waiver will be construed as a waiver in any other instance. Except as expressly
                              agreed by Nestease and you, these ToS, including any Nestease policies governing the Service referenced herein,
                              constitute the entire agreement between you and Nestease with respect to the subject matter hereof, and supersedes all
                              previous or contemporaneous agreements or communications of any kind with respect to the subject matter.
                         </p>
                    </div>
               </div>
          </div>
     </div>
</template>

<script>
     export default {
          mounted() {
               // this.$refs.video.play();
          },
     };
</script>

<style scoped lang="scss">
     .banner {
          background: url("../assets/images/about/section2banner.jpg");

          height: 400px;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: cover;
          display: flex;
          align-items: flex-end;
          padding-bottom: 80px;
          h1 {
               color: white;
               font-size: 80px;
          }
     }

     .content {
          padding-top: 50px;
          padding-bottom: 100px;
          h2 {
               font-weight: 500;
               text-transform: uppercase;
               margin-top: 50px !important;
               max-width: 650px;
          }

          p {
               text-align: justify;
               max-width: 650px;
          }

          li {
               text-align: justify;
               max-width: 500px;
               margin-bottom: 20px;
          }

          @media screen and (max-width: 700px) {
               padding-top: 50px;
               padding-bottom: 50px;
          }
     }
     .container {
          padding-left: 16px;
          padding-right: 16px;
     }
</style>
